import React, { useState } from 'react';
import MintButton from './MintButton';
import { useEdition } from '../hooks/useEdition';

// Allow List
import { AllowList, checkList } from '../config/accessLists';

export default function MintAllowList({ address }) {
  const { edition, isError, isLoading } = useEdition();

  // block is ok
  if (edition.blockNumber < edition.earlyListStartBlock) {
    console.log('edition.blockNumber < edition.earlyListStartBlock');
    return null;
  }

  // on the list
  const { onList, index, proof } = checkList(AllowList, address);
  if (!onList) {
    // console.log('not on allow list');
    return null;
  }

  return onList ? (
    <div className='mintBox'>
      <h3>Giveaway! </h3>
      <p>
        You are on the giveaway list! Use the button below to mint an OddFolk
        for free!
      </p>
      {onList ? (
        <div>
          <MintButton
            label={'Mint'}
            amount={'0'}
            callName={'mintGiveaway'}
            index={index}
            proof={proof}
          />
        </div>
      ) : (
        <p>
          Join our <a href='https://discord.gg/7CkDneMv'>Discord</a> for news on
          upcoming editions.
        </p>
      )}
    </div>
  ) : null;
}
