import { useState, useEffect } from 'react';
import { useContractRead, useProvider } from 'wagmi';
import { ABI } from '../config/oddFolksABI';

export function useEdition(friendID) {
  const [edition, setEdition] = useState({});
  const [blockNumber, setBlockNumber] = useState(0);
  const [chainId, setChainId] = useState(0);

  const provider = useProvider();
  const { data, isError, isLoading } = useContractRead(
    {
      addressOrName: '0xF4Ad8118926F5d01854EE77F338C07F3EB026371',
      contractInterface: ABI,
    },
    'editions',
    { args: '1' }
  );

  provider.getBlockNumber().then((block) => {
    // console.log(block);
    setBlockNumber(block);
  });

  provider.getNetwork().then((network) => {
    // console.log(network);
    setChainId(network?.chainId);
  });

  useEffect(() => {
    if (data) {
      const struct = extractStruct(data, outputs);
      setEdition({ blockNumber, chainId, ...struct });
    }
  }, [data, blockNumber, chainId]);

  return { edition, isError, isLoading };
}

function extractStruct(dataArray, output) {
  const object = {};

  output.forEach((item, index) => {
    object[item.name] = dataArray[index].toString();
  });

  return object;
}
const outputs = [
  {
    internalType: 'uint256',
    name: 'id',
    type: 'uint256',
  },
  {
    internalType: 'uint256',
    name: 'maxMint',
    type: 'uint256',
  },
  {
    internalType: 'uint256',
    name: 'publicPrice',
    type: 'uint256',
  },
  {
    internalType: 'uint256',
    name: 'publicStartBlock',
    type: 'uint256',
  },
  {
    internalType: 'bytes32',
    name: 'allowListRoot',
    type: 'bytes32',
  },
  {
    internalType: 'uint256',
    name: 'allowListStartBlock',
    type: 'uint256',
  },
  {
    internalType: 'uint256',
    name: 'earlyListPrice',
    type: 'uint256',
  },
  {
    internalType: 'bytes32',
    name: 'earlyListRoot',
    type: 'bytes32',
  },
  {
    internalType: 'uint256',
    name: 'earlyListStartBlock',
    type: 'uint256',
  },
  {
    internalType: 'uint256',
    name: 'minted',
    type: 'uint256',
  },
];
