import React from 'react';
import { useEffect, useState } from 'react';

const images = [
  {
    imageURL:
      'https://pbs.twimg.com/media/FIxKrHKX0Acpstt?format=jpg&name=large',
  },
  {
    imageURL:
      'https://pbs.twimg.com/media/FLfwrP8XEAUL238?format=jpg&name=large',
  },
  {
    imageURL:
      'https://pbs.twimg.com/media/FL1_8XbXEAA0u4e?format=jpg&name=large',
  },

  {
    imageURL:
      'https://pbs.twimg.com/media/FLfxdzdXMAQzkCW?format=jpg&name=large',
  },
  {
    imageURL:
      'https://pbs.twimg.com/media/FKMgwY9XwAIGXMV?format=jpg&name=large',
  },
];

export default function SlideShow() {
  const [counter, setCounter] = useState(0);
  const delay = 3.6e3;

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((counter) => {
        if (counter < images.length - 1) {
          return counter + 1;
        } else {
          return 0;
        }
      });
    }, delay);
    return () => clearInterval(interval);
  }, []);

  // console.log('render: ', counter, items.length);

  return (
    <div>
      <img
        src={images[counter].imageURL}
        alt='avatar'
        style={{ borderRadius: '50%', height: '300px', margin: '0 auto' }}
      />
    </div>
  );
}
