import { Provider, chain, createClient, defaultChains } from 'wagmi';
import { ethers, providers } from 'ethers';

import { useAccount, useConnect } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { Buffer } from 'buffer';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';

import SlideShow from './components/SlideShow';
import MintAllowList from './components/MintAllowList';
import MintEarlyList from './components/MintEarlyList';
import MintPublic from './components/MintPublic';
import Edition from './components/Edition';

const chains = defaultChains;
const defaultChain = chain.mainnet;

// polyfill Buffer for client
if (!window.Buffer) {
  window.Buffer = Buffer;
}

// const provider = ({ chainId }) => {
//   return new providers.AlchemyProvider(chainId, alchemyId);
// };

// new providers.JsonRpcProvider('http://localhost:8545', 31337)
// const infuraProvider_rinkeby = new ethers.providers.InfuraProvider(
//   'rinkeby',
//   '3bd4b032abf14871a9fc0f2574e0016f'
// );
const infuraProvider = new ethers.providers.InfuraProvider(
  'homestead',
  '3bd4b032abf14871a9fc0f2574e0016f'
);

// Set up connectors
const client = createClient({
  autoConnect: true,
  connectors({ chainId }) {
    const chain = chains.find((x) => x.id === chainId) ?? defaultChain;
    const rpcUrl = chain.rpcUrls.default;
    return [
      new MetaMaskConnector({ chains }),
      new WalletConnectConnector({
        chains,
        options: {
          qrcode: true,
          rpc: { [chain.id]: rpcUrl },
        },
      }),
      new InjectedConnector({
        chains,
        options: { name: 'Injected' },
      }),
    ];
  },
  provider: infuraProvider,
});

function App() {
  return (
    <Provider client={client}>
      {/* <Profile /> */}

      <div className='app'>
        {/* Hero */}
        <div className='hero'>
          <SlideShow />
          <h1>OddFolk</h1>
          <p>
            <b>OddFolk</b> is the first generative-art NFT project by
            Dublin-based artist Andrew Woodcock.
          </p>

          <SocialLinks />
        </div>

        <hr />

        <section>
          <SeriesOne />
        </section>

        <footer>
          <SocialLinks />
          <p>
            <a href='https://raritysniper.com/nft-drops-calendar'>
              Rarity Sniper Drops
            </a>
          </p>
        </footer>
      </div>
    </Provider>
  );
}

export default App;

function SocialLinks() {
  return (
    <div style={{ disply: 'flex', marginBottom: '1rem' }}>
      <a href='https://discord.gg/7CkDneMv'>Discord</a>
      <span> | </span>
      <a href='https://twitter.com/OddFolkNFT'>Twitter</a>
      <span> | </span>
      <a href='https://www.instagram.com/OddFolkNFT/'>Instagram</a>
    </div>
  );
}

function SeriesOne() {
  const { data, isError, isLoading } = useAccount();

  return (
    <>
      {data?.address ? (
        <>
          <Edition editionNumber={'1'} />
          <MintAllowList address={data?.address} />
          <MintEarlyList address={data?.address} />
          <MintPublic address={data?.address} />
        </>
      ) : (
        <Profile />
      )}
    </>
  );
}

export function Profile() {
  const {
    connect,
    connectors,
    error,
    isConnecting,
    pendingConnector,
    activeConnector,
  } = useConnect();

  const { data, isError, isLoading } = useAccount();
  // console.log(data);
  return (
    <div>
      {activeConnector ? (
        <div>
          <div>Connected to {activeConnector.name}</div>
          <div>Connected to {data?.address}</div>
        </div>
      ) : (
        <div>
          <div style={{ marginBottom: '1rem' }}></div>
          <h3>Connect Your Wallet</h3>
          {connectors
            .filter((cxn) => {
              console.log(cxn.name, cxn.ready);
              return cxn.ready;
            })
            .map((connector) => (
              <button
                className='font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline border-2 border-blue-500 text-blue-500 bg-white'
                disabled={!connector.ready}
                key={connector.id}
                onClick={() => connect(connector)}>
                {connector.name}
                {isConnecting &&
                  connector.id === pendingConnector?.id &&
                  ' (connecting)'}
              </button>
            ))}
        </div>
      )}

      {error && <div>{error.message}</div>}
    </div>
  );
}
