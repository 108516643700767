import React, { useState, useEffect } from 'react';
import { useEdition } from '../hooks/useEdition';

export default function Edition({ editionNumber }) {
  const { edition, isError, isLoading } = useEdition();

  // console.log(edition);

  return (
    <div style={{ marginTop: '2rem' }}>
      <h2>Series {edition.id}</h2>
      <p>Editions: {edition.maxMint}</p>
      <p>
        Minted: {edition.minted} of {edition.maxMint}
      </p>
      <div>{isLoading ? 'Loading...' : ''}</div>
      <div>
        {isError ? (
          <span>
            An error has occured. Join our{' '}
            <a href='https://discord.gg/7CkDneMv'>Discord</a> for support{' '}
          </span>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
