import React, { useState } from 'react';
import MintButton from './MintButton';

export default function MintPublic() {
  return (
    <div className='mintBox'>
      <h3>Public Sale</h3>
      <p>Use the button below to mint an OddFolk before they are gone! </p>
      <div>
        <MintButton
          label={'Mint - 0.08ETH'}
          amount={'0.08'}
          callName={'mint'}
        />
      </div>
    </div>
  );
}
