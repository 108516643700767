import React, { useState } from 'react';
import MintButton from './MintButton';
import { useEdition } from '../hooks/useEdition';
import { EarlyList, checkList } from '../config/accessLists';

export default function MintEarlyList({ address }) {
  const { edition, isError, isLoading } = useEdition();

  // block is ok
  if (edition.blockNumber < edition.earlyListStartBlock) {
    console.log('edition.blockNumber < edition.earlyListStartBlock');
    return null;
  }

  // on the list
  const { onList, index, proof } = checkList(EarlyList, address);
  if (!onList) {
    // console.log('not on early list');
    return null;
  }

  return onList ? (
    <div className='mintBox'>
      <h3>Early Access</h3>
      <p>
        You are on the early access list! Use the button below to mint an
        OddFolk for just 0.06ETH!
      </p>
      <div>
        <MintButton
          label={'Mint - 0.06ETH'}
          amount={'0.06'}
          callName={'mintEarlyList'}
          index={index}
          proof={proof}
        />
      </div>
    </div>
  ) : null;
}
