export const AllowList = [
  '0x0B199951975654564241549D6D2794F990F49eD1',
  '0x13bF59c1014931a4EF1Afe275Ddd4878fD294C10',
  '0x29b857eF75f804D9F7CfF3edE43FA98072A048F4',
  '0xBe3178f11B55787A0827E9f28d8759761f21E3C9',
  '0x27E1dcFff7CcDc469831Be05b180727FF90b235b',
  '0x8c78ff979276eed38e1de634e60e27d48fe6caee',
  '0xD6003187c98b2Cf5249D5b5c86d833ce47B4E94f',
  '0x8309d40460874126535312ca43363882dbb9e813',
  '0x02a16834b46742E31e239e1f887fcD881f76a412',
  '0x975C1c5747d08620614130F23FB77942ba46741A',
  '0x161233E46AfD3FF6731abEF9Fd8016f6A467257a',
  '0x7A152E5b434Cf8511bfabDDb4f35b604dd8289Bd',
  '0xB38079F34076d9d7340E27CA305ab938f3d35bB2',
  '0x310637C37f1f5A686448b5Acc5DdD4c727B84d79',
  '0xCF2DA4685aAfBF9f83970B93DE495D7f4036B62E',
  '0x9c997d5690aea645A5FfFa22Ac0377c796C2AE95',
  '0x415c5fe1bdbe78A6244Af45Fc45774676755bFe4',
  '0xe99dDe5368406876A73f628B1c6A951df16836F3',
  '0x9846aAa48BbF433e02cE9ca84c2aCC1d8b92A46c',
  '0x6FE22d803255277c43dacf1b1817A704204d15dB',
  '0x771e567774b8d8689a8568314debd48fc58705Ae',
  '0x970570Fa91E2777eAE28bC8F12DbF34511e96641',
  '0x09a95Aa957fC597825c9CDcd5517edB68C8612F3',
  '0xab2ff5CEBD2800eC96d58e2bEEf1B1C087eB3E3E',
  '0x4AE4b72c49F538f40E90c4499F5Dc38134D2e7A6',
  '0xD26B33ABAe4cAbe83755Be4cb89068E001DE3344',
  '0xD184B496874221b2CcB594245e6164D8401554FA',
  '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266',
];

export const EarlyList = [
  '0x0B199951975654564241549D6D2794F990F49eD1',
  '0x13bF59c1014931a4EF1Afe275Ddd4878fD294C10',
  '0x29b857eF75f804D9F7CfF3edE43FA98072A048F4',
  '0xBe3178f11B55787A0827E9f28d8759761f21E3C9',
  '0x27E1dcFff7CcDc469831Be05b180727FF90b235b',
  '0x8c78ff979276eed38e1de634e60e27d48fe6caee',
  '0xD6003187c98b2Cf5249D5b5c86d833ce47B4E94f',
  '0x8309d40460874126535312ca43363882dbb9e813',
  '0x02a16834b46742E31e239e1f887fcD881f76a412',
  '0x975C1c5747d08620614130F23FB77942ba46741A',
  '0x161233E46AfD3FF6731abEF9Fd8016f6A467257a',
  '0x7A152E5b434Cf8511bfabDDb4f35b604dd8289Bd',
  '0xB38079F34076d9d7340E27CA305ab938f3d35bB2',
  '0x310637C37f1f5A686448b5Acc5DdD4c727B84d79',
  '0xCF2DA4685aAfBF9f83970B93DE495D7f4036B62E',
  '0x9c997d5690aea645A5FfFa22Ac0377c796C2AE95',
  '0x415c5fe1bdbe78A6244Af45Fc45774676755bFe4',
  '0xe99dDe5368406876A73f628B1c6A951df16836F3',
  '0x9846aAa48BbF433e02cE9ca84c2aCC1d8b92A46c',
  '0x6FE22d803255277c43dacf1b1817A704204d15dB',
  '0x771e567774b8d8689a8568314debd48fc58705Ae',
  '0x970570Fa91E2777eAE28bC8F12DbF34511e96641',
  '0x09a95Aa957fC597825c9CDcd5517edB68C8612F3',
  '0xab2ff5CEBD2800eC96d58e2bEEf1B1C087eB3E3E',
  '0x4AE4b72c49F538f40E90c4499F5Dc38134D2e7A6',
  '0xD26B33ABAe4cAbe83755Be4cb89068E001DE3344',
  '0xD184B496874221b2CcB594245e6164D8401554FA',
  '0x9E5082EA822aFD947B755b19aC3732f3F09BCefa',
  '0x565e1b1cD6AC9891d268643e74Bf5a44c9F0FA5b',
  '0x421380a543455A5A3bF645a172D38b6E54055443',
  '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266',
];

const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');
const ethers = require('ethers');
function hashLeaf(index, account) {
  // console.log({ index, account });

  return Buffer.from(
    ethers.utils
      .solidityKeccak256(['uint256', 'address'], [index, account])
      .slice(2),
    'hex'
  );
}
function setupMerkle(array) {
  return new MerkleTree(
    array.map((token, index) => {
      return hashLeaf(index, token.toLowerCase());
    }),
    keccak256,
    { sortPairs: true }
  );
}

export function checkList(list, address) {
  // to lower list
  const newList = list.map((item) => item.toLowerCase());
  const index = newList.indexOf(address.toLowerCase());
  if (index <= -1) {
    return { onList: false };
  }

  const EarlyListTree = setupMerkle(list);
  const addressLeaf = hashLeaf(index, address.toLowerCase());
  const proof = EarlyListTree.getHexProof(addressLeaf);

  // console.log({ onList: true, index, proof });
  return { onList: true, index, proof };
}
