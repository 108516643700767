import React, { useState, useEffect } from 'react';
import { useContractWrite } from 'wagmi';
import { ethers } from 'ethers';

import { ABI } from '../config/oddFolksABI';
import { useEdition } from '../hooks/useEdition';

export default function MintButton({ label, amount, callName, index, proof }) {
  const { edition } = useEdition();
  // const edition = 1;/

  const { data, isError, isLoading, write } = useContractWrite(
    {
      addressOrName: '0xF4Ad8118926F5d01854EE77F338C07F3EB026371',
      contractInterface: ABI,
    },
    callName
  );

  return (
    <div>
      <button
        onClick={async () => {
          await write({
            args: [edition.id, index || 0, proof || []],
            overrides: {
              value: ethers.utils.parseEther(amount),
            },
          });
        }}
        className='font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline border-2 border-blue-500 text-blue-500 bg-white'>
        {label || 'Mint'}
      </button>

      <div style={{ marginTop: '1rem' }}>
        <div>{isLoading ? 'Loading...' : ''}</div>
        {isError ? (
          <span>
            An error has occurred, or this item has already been claimed. Check
            out our <a href='https://discord.gg/7CkDneMv'>Discord</a> for
            support{' '}
          </span>
        ) : null}
      </div>
      <div>
        {data ? (
          <span>
            Success! Watch our <a href='https://discord.gg/7CkDneMv'>Discord</a>{' '}
            for more news!{' '}
          </span>
        ) : null}
      </div>
    </div>
  );
}
